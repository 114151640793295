:root,
[data-bs-theme=light] {
  --bs-primary: #FFCC00;
  --bs-primary-rgb: 255, 204, 0;
  --bs-primary-text-emphasis: #665200;
  --bs-primary-bg-subtle: #FFF5CC;
  --bs-primary-border-subtle: #FFEB99;
  --bs-body-color: #0c0c0c;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(255, 255, 255, 0.75);
  --bs-secondary-color-rgb: 255, 255, 255, 0.75;
  --bs-tertiary-color: rgba(255, 255, 255, 0.5);
  --bs-tertiary-color-rgb: 255, 255, 255, 0.5;
  --bs-body-bg: #00843D;
  --bs-body-bg: rgba(0, 132, 61, 0.8);
  /*Adjust the opacity level as needed */
}

.btn-primary {
  --bs-btn-color: #000000;
  --bs-btn-bg: #FFCC00;
  --bs-btn-border-color: #FFCC00;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #FFD426;
  --bs-btn-hover-border-color: #FFD119;
  --bs-btn-focus-shadow-rgb: 38, 31, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #FFD633;
  --bs-btn-active-border-color: #FFD119;
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #FFCC00;
  --bs-btn-disabled-border-color: #FFCC00;
}

.btn-confirmation {
  --bs-btn-color: #000000;
  --bs-btn-bg: rgba(0, 132, 61, 0.8);
  --bs-btn-border-color: rgba(0, 132, 61, 0.8);
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: rgba(0, 132, 61, 0.8);
  --bs-btn-hover-border-color: rgba(0, 132, 61, 0.8);
  --bs-btn-focus-shadow-rgb: 38, 31, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: rgba(0, 132, 61, 0.8);
  --bs-btn-active-border-color: rgba(0, 132, 61, 0.8);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: rgba(0, 132, 61, 0.8);
  --bs-btn-disabled-border-color: rgba(0, 132, 61, 0.8);
}

.btn-outline-primary {
  --bs-btn-color: #FFCC00;
  --bs-btn-border-color: #FFCC00;
  --bs-btn-focus-shadow-rgb: 255, 204, 0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #FFCC00;
  --bs-btn-hover-border-color: #FFCC00;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #FFCC00;
  --bs-btn-active-border-color: #FFCC00;
  --bs-btn-disabled-color: #FFCC00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFCC00;
}

.bs-icon {
  --bs-icon-size: .75rem;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: var(--bs-icon-size);
  width: calc(var(--bs-icon-size) * 2);
  height: calc(var(--bs-icon-size) * 2);
  color: var(--bs-primary);
}

.bs-icon-xs {
  --bs-icon-size: 1rem;
  width: calc(var(--bs-icon-size) * 1.5);
  height: calc(var(--bs-icon-size) * 1.5);
}

.bs-icon-sm {
  --bs-icon-size: 1rem;
}

.bs-icon-md {
  --bs-icon-size: 1.5rem;
}

.bs-icon-lg {
  --bs-icon-size: 2rem;
}

.bs-icon-xl {
  --bs-icon-size: 2.5rem;
}

.bs-icon.bs-icon-primary {
  color: var(--bs-white);
  background: var(--bs-primary);
}

.bs-icon.bs-icon-primary-light {
  color: var(--bs-primary);
  background: rgba(var(--bs-primary-rgb), .2);
}

.bs-icon.bs-icon-semi-white {
  color: var(--bs-primary);
  background: rgba(255, 255, 255, .5);
}

.bs-icon.bs-icon-rounded {
  border-radius: .5rem;
}

.bs-icon.bs-icon-circle {
  border-radius: 50%;
}

.card {
  background-color: #FFCC00;
  border-color: #0c0c0c;
}

.timer {
  text-align: center;
}

.timer-circle {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #FFCC00;
  font-size: 2.5em;
  line-height: 100px;
  border: 1px solid;
}

.timerSmall-circle {
  display: inline-block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #FFCC00;
  font-size: 1.5em;
  line-height: 70px;
  border: 1px solid;
}

.timer-spacer {
  display: inline-block;
  width: 100px;
  height: 100px;
}

.timer-spacer-small {
  display: inline-block;
  width: 70px;
  height: 70px;
}

.list-group-item {
  background-color: #FFCC00;
  border-color: #0c0c0c;
}

.progress {
  border: 1px #0c0c0c solid;
}

.progress-bar {
  background-color: #FFCC00;
  color: #0c0c0c;
}

/*.loading-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.loading-circle-inner {
  border: 16px solid rgba(0, 132, 61, 0.8);
  border-radius: 50%;
  border-top: 16px solid #FFCC00;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}


@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}*/

.hover-effect:hover {
  background-color: #f0f0f0;
  /* Change the background color on hover */
  cursor: pointer;
  /* Change the cursor to pointer on hover */
}

@media (max-width: 767.98px) {
  .ml-sm {
    margin-left: 20px;
  }
}

body {
  font-family: 'Nunito', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
}

.banner-image {
  width: 50%;
  height: auto;
  margin-bottom: 1rem;
}

.btn-modal {
  --bs-btn-color: white;
  --bs-btn-bg: #FFCC00;
  --bs-body-bg: #00843D;
}

.modal-body {
  background-color: #FFCC00;
}

.modal {
  background-color: rgba(0, 132, 61, 0.8);
  ;
  /* Change the last value to 1 for full opacity */
}


/*scoreboard************************************************************************************************/

/*.scoreboard-container {
  width: 100%;
  padding: 20px;
}

.scoreboard-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  display: none;
}

.scoreboard-table th,
.scoreboard-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.scoreboard-table th {
  background-color: #f2f2f2;
}

.scoreboard-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-card {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 90%;
  max-width: 400px;
}

.profile-picture {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.username {
  margin: 5px 0;
}

.score {
  margin: 5px 0;
}

.answer-percentage {
  margin: 5px 0;
}

@media (min-width: 768px) {
  .scoreboard-table {
    display: table;
  }

  .scoreboard-cards {
    display: none;
  }
}*/

/*Navbar CSS******************************************************************************************************************/

.nav-link-hover {
  transition: color 0.3s ease-in-out;
}

.nav-link-hover:hover {
  color: #fff;
}

.logo {
  height: 75px;
}

@media (min-width: 768px) {

  /* Large devices and above */
  .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .logo {
    height: 100px;
  }
}


btn:focus,
a:focus {
  outline: none;
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none;
}

/*AuthPage css*/
.message-box {
  background-color: #f09a37;
  /* Match the background color of btn-light */
  padding: 10px;
  border-radius: 5px;
  max-width: 320px;
  /* Match the width of form fields */
  color: black;
  /* Text color */
  overflow-wrap: break-word;
  /* Ensure long messages wrap within the box */
  box-shadow: none;
}

/*AuthPage css*/
.message-box-settings {
  background-color: #f09a37;
  /* Match the background color of btn-light */
  padding: 10px;
  border-radius: 5px;
  /* Match the width of form fields */
  color: black;
  /* Text color */
  overflow-wrap: break-word;
  /* Ensure long messages wrap within the box */
  box-shadow: none;
}

.forgot-password {
  color: blue;
  text-decoration: underline;
}

.forgot-password:hover {
  text-decoration: none;
}

/*Captcha css*/

@media only screen and (max-width: 400px) {
  .captcha-box {
    max-width: 220px;
    display: flex;
    justify-content: center;
    padding-left: 70px;
    padding-top: 20px;
  }

  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

/* Settings Modal*/

.selected-profile-picture {
  border: 3px solid #e3e9f0;
  /* Highlight the selected profile picture */
  box-shadow: 0 0 10px rgba(130, 182, 239, 0.5);
}

/* loading circle and referal link */

.loading-circle-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height to center vertically */
  text-align: center;
}

.loading-circle-inner {
  border: 16px solid rgba(0, 132, 61, 0.8);
  border-radius: 50%;
  border-top: 16px solid #FFCC00;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-bottom: 20px;
  /* Creates space between loading circle and referral link */
}

.amazon-referral {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #ffcc00 0%, #ffc107 50%, #ff9800 100%);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.referral-content {
  text-align: center;
  max-width: 90%;
  /* Adjust based on viewport */
}

.referral-content h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 15px;
}

.referral-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
  max-width: 80%;
  /* Reduced max-width */
  /* Limit width to container */
  max-height: 40vh;
  /* Reduced max-height */
  /* Limit height based on viewport */
}

.referral-image:hover {
  transform: scale(1.05);
}

.referral-text {
  font-family: 'Nunito', sans-serif;
  color: #333;
  margin-bottom: 15px;
}

.referral-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #00843d;
  color: #fff;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.referral-button:hover {
  background-color: #005c2e;
  transform: translateY(-3px);
}

/* Media query to adjust layout for smaller screens */
@media (max-width: 768px) {
  .referral-content {
    max-width: 100%;
    /* Allow content to take full width on small screens */
  }

  .referral-image {
    max-height: 60vh;
    /* Adjust max height for smaller screens */
  }
}

@media (max-width: 480px) {
  .referral-content h2 {
    font-size: 1.2rem;
    /* Adjust heading size for very small screens */
  }

  .referral-image {
    max-height: 25vh;
    /* Further adjust max height for very small screens */
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.card-wrapper {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
  /* Slightly transparent background */
  z-index: 10;
  /* Ensure it appears on top */
}

/*Cookie Policy Pop-Up*/

.cookie-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 20px;
  /* Added padding to ensure content is not too close to the edges on smaller screens */
}

.cookie-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  width: 80%;
  /* Adjust width for responsiveness */
}

.cookie-content h2 {
  margin-bottom: 15px;
  font-size: 24px;
  color: #333;
}

.cookie-content p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #555;
}

.cookie-buttons {
  display: flex;
  flex-direction: column;
  /* Stack buttons vertically on smaller screens */
  gap: 10px;
  /* Space between buttons */
}

.accept-button,
.learn-more-button {
  background-color: #00843D;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  /* Full width buttons for better touch interaction on small screens */
}

.learn-more-button {
  background-color: #FFCC00;
  color: black;
}

.accept-button:hover,
.learn-more-button:hover {
  opacity: 0.9;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .cookie-content {
    max-width: 90%;
    /* Adjust max-width for very small screens */
    padding: 15px;
    /* Reduce padding to fit content better on small screens */
  }

  .cookie-content h2 {
    font-size: 20px;
    /* Adjust font size */
  }

  .cookie-content p {
    font-size: 14px;
    /* Adjust font size */
  }

  .accept-button,
  .learn-more-button {
    font-size: 14px;
    /* Adjust button font size */
    padding: 8px 15px;
    /* Adjust button padding */
  }
}

/*Privacy Policy*/

.privacy-policy-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #FFCC00;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.privacy-policy-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.privacy-policy-container h2 {
  font-size: 1.75rem;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #555;
}

.privacy-policy-container p {
  font-size: 1.125rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #666;
}


/*cookie policy */
.cookie-policy-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #FFCC00;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cookie-policy-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}

.cookie-icon {
  font-size: 2.5rem;
  color: #00843D;
  margin-right: 10px;
}

.cookie-policy-header h1 {
  font-size: 2rem;
  color: #333;
}

.cookie-policy-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
}

.cookie-policy-content strong {
  color: #00843D;
}

.privacy-link {
  color: #1900ff;
  text-decoration: none;
  font-weight: bold;
}

.privacy-link:hover {
  text-decoration: underline;
}

/* Custom CSS to remove padding */
.no-gutter {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/*Glow Button*/

.glow-button {
  background-color: white;
  color: black;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  z-index: 0;
}

.glow-button::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background: rgba(240, 243, 247, 0.932);
  z-index: -1;
  border-radius: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.glow-button:hover::before {
  opacity: 1;
}

.glow-button:hover {
  background-color: white;
}

.glow-button:hover {
  box-shadow: 0 0 20px rgba(242, 243, 245, 0.932), 0 0 30px rgba(240, 243, 247, 0.932), 0 0 40px rgba(240, 243, 247, 0.932);
  transition: 0.3s;
}

.glow-button:active {
  box-shadow: none;
}