.developer-image {
    border: 4px solid #0077b5;
}

.linkedin-icon {
    color: #0077b5;
}

.icon {
    margin-left: 10px;
    margin-right: 10px;
    color: #00843D;
}

@media (max-width: 768px) {
    .developer-text {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .developer-text {
        width: 75%;
    }
}