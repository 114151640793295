/* Search bar container styling */
.search-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    width: 90%;
}

/* Search input styling */
.search-input {
    width: 70%;
    margin-right: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

/* Responsive styling for search input */
@media (min-width: 992px) {
    .search-input {
        width: 50%;
    }
}

/* Toggle switch container */
.toggle-switch {
    display: flex;
    align-items: center;
    cursor: pointer;
}

/* Hide the checkbox input inside the toggle switch */
.toggle-switch input {
    display: none;
}

/* Slider design inside toggle switch */
.toggle-switch .slider {
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 20px;
    position: relative;
    transition: background-color 0.2s;
}

/* Slider handle */
.toggle-switch .slider:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    transition: transform 0.2s;
}

/* When the toggle is checked, change the slider background and move the handle */
.toggle-switch input:checked+.slider {
    background-color: #66bb6a;
}

.toggle-switch input:checked+.slider:before {
    transform: translateX(20px);
}

/* Toggle switch label */
.toggle-label {
    margin-left: 10px;
    font-size: 1rem;
}

/* Trophy icon styling */
.trophy-icon {
    font-size: 1.2rem;
    position: absolute;
    right: 10px;
    top: 10px;
}

/* Trophy colors based on rank */
.trophy-1 {
    color: rgb(210, 179, 3);
}

.trophy-2 {
    color: silver;
}

.trophy-3 {
    color: #cd7f32;
    /* Bronze */
}

/* Rank icon and number styling */
.rank-icon {
    font-size: 1.2rem;
    color: #eb8c08;
    margin-right: 5px;
}

.rank-number {
    font-size: 1rem;
    font-weight: bold;
}

/* Container for user rank display */
.user-rank {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

/* User card styling */
.user-card {
    position: relative;
    margin: 20px auto;
    padding: 20px;
    width: 90%;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: transform 0.2s;
    overflow: hidden;
    /* Ensure content stays within card */
}

/* User card hover effect */
.user-card:hover {
    transform: scale(1.05);
}

/* Profile picture styling */
.profile-picture {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px;
}

/* Responsive profile picture sizes */
@media (min-width: 321px) {
    .profile-picture {
        width: 75px;
        height: 75px;
    }
}

@media (min-width: 576px) {
    .profile-picture {
        width: 100px;
        height: 100px;
    }
}

@media (min-width: 768px) {
    .profile-picture {
        width: 100px;
        height: 100px;
    }
}

/* Container for card content */
.card-content {
    display: flex;
    align-items: center;
}

/* Container for card details */
.card-details {
    text-align: left;
    width: 100%;
}

/* Username styling with text overflow handling */
.card-title {
    font-size: 1.2rem;
    white-space: nowrap;
    /* Prevent wrapping */
    overflow: hidden;
    /* Hide overflowing text */
    text-overflow: ellipsis;
    /* Show ellipsis for long usernames */
    max-width: 100%;
    padding-right: 10px;
    /* Add padding to avoid touching the edge */
    box-sizing: border-box;
    /* Ensure padding is considered in width */
}

/* For screens 321px and smaller */
@media (max-width: 321px) {
    .card-title {
        padding-right: 15px;
        /* Add more padding for small screens */
        font-size: 1rem;
        /* Adjust font size */
    }
}

@media (min-width: 576px) {
    .card-title {
        font-size: 1.5rem;
    }
}

@media (min-width: 992px) {
    .card-title {
        font-size: 1.75rem;
    }
}

/* User statistics container */
.user-stats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    font-size: 1rem;
    color: #555;
}

/* Individual user stat container */
.user-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

/* Styling for user stat values */
.user-stat-value {
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
}

/* Info button styling */
.info-button {
    margin-left: 5px;
    cursor: pointer;
    color: #FFCC00;
    /* Updated color */
    font-size: 1.2rem;
    border: none;
    background: none;
    outline: none;
    position: relative;
}

/* Tooltip styling - default for larger screens (>992px) */
.tooltip {
    visibility: hidden;
    opacity: 0;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 125%;
    /* Center horizontally */
    transform: translateX(-40%);
    /* Center the tooltip */
    transition: opacity 0.3s;
    font-size: 0.8rem;
}

/* Tooltip arrow */
.tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Tooltip visibility on hover */
.info-button:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

/* For screens between 601px and 992px */
@media (max-width: 992px) and (min-width: 601px) {
    .tooltip {
        /* Shift the tooltip to the left */
        transform: translateX(-80%);
        /* Adjust to prevent cutoff on right */
    }
}

/* For screens 600px and smaller */
@media (max-width: 600px) {
    .tooltip {
        transform: translateX(-80%);
        /* Prevent right side cutoff */
        width: 180px;
        /* Slightly reduce width for smaller screens */
    }
}

/* Additional fix for very small screens (e.g., <=321px) */
@media (max-width: 321px) {
    .tooltip {
        /* Further shift tooltip to the left */
        transform: translateX(-80%);
        width: 160px;
        /* Narrower tooltip for extra small screens */
    }
}